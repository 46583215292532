import { Component, EventEmitter, OnInit, Output, Input, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'query-string-modal',
  templateUrl: './query-string-modal.component.html',
})
export class QueryStringModalComponent implements OnInit {
  @Input() list: any;

  @Output() close = new EventEmitter();
  @Output() getDatasourceList = new EventEmitter();

  api_queryStrings: Array<any> = [];

  constructor(private cdr: ChangeDetectorRef, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.list) {
      this.api_queryStrings = this.list;
    }
  }

  _close() {
    this.activeModal.close(this.api_queryStrings);
  }

  onAddQueryStringsApi() {
    this.api_queryStrings.push({
      property: '',
      value: '',
    });
    this.cdr.detectChanges();
    return;
  }

  onDelQueryStringsApi(index) {
    this.api_queryStrings.splice(index, 1);
  }
}
