import { MatTableDataSource } from '@angular/material/table';
import { get_format_date } from 'src/app/libs/helpers/utility';
import { axisFormater, CHART_CONSTANTS } from '.';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

const formatingDate = (value: string, formatVal: any) => {
  const dateStr = moment(value).format(get_format_date(formatVal));
  return dateStr === 'Invalid date' ? value : dateStr;
};

const formatRecords = (records: any, data: any) => {
  const color = CHART_CONSTANTS.general.collorPalette[data.form_data.color_scheme];
  const formattedRecods = records.map((row: any, index: number) => {
    let formattedRow = {};
    const customFormatArr = data.form_data.custom_column_format_arr;
    Object.keys(row).map((key) => {
      const customFormatObj = customFormatArr.find((obj: any) => obj.column === key);
      if (customFormatObj) {
        formattedRow[key] =
          customFormatObj.format_type === 'number'
            ? axisFormater(row[key], data, customFormatObj.format)
            : formatingDate(row[key], customFormatObj.format);
      } else {
        if (typeof row[key] === 'number') {
          formattedRow[key] = row[key] % 1 !== 0 ? axisFormater(row[key], data, '.3f') : row[key];
        } else if (row[key].viz_type) {
          formattedRow[key] = {
            silent: true,
            grid: {
              left: '10%',
              right: '10%',
              top: '15%',
              bottom: '15%'
            },
            xAxis: {
              type: 'category',
              show : false,
            },
            yAxis: {
              type: 'value',
              show : false,
            },
            series: [
              {
                data: row[key].value,
                type: row[key].viz_type,
                showSymbol: false,
                itemStyle: {
                  color: color[0]
                }
              }
            ]
          };
        } else {
          formattedRow[key] = row[key];
        }    
      }
    });

    return data.form_data.static_number ? { ...formattedRow, No: (data.form_data.page_index - 1) * data.form_data.page_size + index + 1 } : formattedRow;
  });

  return { formattedRecods, realRecords: records };
};

const completeRecords = (data: any) => {
  if (data.form_data.show_total_numeric_column) {
    let totalValueCol = {};
    let index = 0;

    for (var prop in data.data.records[0]) {
      if (Object.prototype.hasOwnProperty.call(data.data.records[0], prop)) {
        let numericCol = data.table_sum[prop];
        if (prop === data.data.columns[0]) {
          totalValueCol[prop] = 'Total';
        } else {
          if (numericCol !== undefined) {
            totalValueCol[prop] = numericCol;
          } else {
            totalValueCol[prop] = '';
          }
        }
        index++;
      }
    }

    return formatRecords([...data.data.records, totalValueCol], data);
  }

  return formatRecords(data.data.records, data);
};

export const setConfigSparklineTable = (data: any, sort: any) => {
  let dataSource = new MatTableDataSource();
  let displayedColumns = data.form_data.static_number ? ['No', ...data.data.columns] : data.data.columns;

  const recordsObj = completeRecords(data);
  dataSource.data = recordsObj.formattedRecods;

  const cellStyle = {
    'font-size': (data.form_data.table_font_size || 10) + 'px',
    'font-family': data.form_data.table_font_family || 'Roboto',
    width: '100%',
  };

  const headerStyle = {
    'margin-right': '32px',
    ...cellStyle,
  };

  const customColumnFormatArr = data.form_data.custom_column_format_arr;
  let flexStyles = {};

  displayedColumns.map((column: string) => {
    const customColumnFormat = customColumnFormatArr.find((obj: any) => obj.column === column);
    const columnFormatStyle = customColumnFormat?.format_type === 'number' ? 'text-align:right' : 'text-align:left';

    const columnFlex = data.form_data.custom_width_column_arr.find((obj: any) => obj.column === column);
    const columnStyle = columnFlex ? columnFlex.width : 'auto;';

    flexStyles[column] = columnStyle + columnFormatStyle;
  });

  return { dataSource, displayedColumns, cellStyle, headerStyle, flexStyles };
};
