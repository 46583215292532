import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { NavigationEnd, Router, Event, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil, withLatestFrom } from 'rxjs';
import * as objectPath from 'object-path';

import {
  ApiService,
  AuthenticationService,
  DataProcessingQueryDatasetService,
  DataProcessingWorkspaceService,
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from 'src/app/libs/services';

import idn from 'src/assets/data/config/language/id.json';
import eng from 'src/assets/data/config/language/en.json';

import { LayoutConfigModel } from 'src/app/libs/models/common';
import { environment } from 'src/environments/environment';
import { AppState } from './libs/store/states';
import { ModalDialogService } from './libs/services/common/modal-dialog.service';
import { LoginModalComponent } from './components/modals';
import { loggedInSelector } from './libs/store/selectors/authentication.selectors';
import { Login } from './libs/store/actions/authentication.actions';
import { PROGRESS_STATE } from './libs/consts';
import { UrlService } from './libs/services/common/url.service';
import { RemoveAllToastrMessage, SetToastrMessage } from './libs/store/actions/pds/dataprocessing.actions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[pq-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  public title = 'ng-paques';
  public loader: boolean;
  public needReLogin: boolean = false;
  public config: LayoutConfigModel = JSON.parse(localStorage.getItem('layoutConfig'));

  private unsubscribe$: Subject<void> = new Subject();
  private resizeObserver: ResizeObserver;
  private modalRef: any = null;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private dataProcessingQueryDatasetService: DataProcessingQueryDatasetService,
    private dataProcessingWorkspaceService: DataProcessingWorkspaceService,
    private elementRef: ElementRef,
    private layoutConfigService: LayoutConfigService,
    private modalDialogService: ModalDialogService,
    private router: Router,
    private splashScreenService: SplashScreenService,
    private store: Store<AppState>,
    private translationService: TranslationService,
    private urlService : UrlService
  ) {
    this.translationService.loadTranslations(eng, idn);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.urlService.setPrevUrl(event.url)
      });
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.removeAttribute('ng-version');

    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    this.authenticationService
      .getNeedReloginPageSubject()
      .pipe(takeUntil(this.unsubscribe$), withLatestFrom(this.store.select(loggedInSelector)))
      .subscribe(([needReloginPage, isLoggedIn]) => {
        if (isLoggedIn && needReloginPage) {
          this.authenticationService.updateNeedReloginStateSubject(PROGRESS_STATE.IN_PROGRESS);

          this.router.navigate(['/auth']);
        }
      });

    this.authenticationService
      .getNeedReloginPopupSubject()
      .pipe(takeUntil(this.unsubscribe$), withLatestFrom(this.store.select(loggedInSelector)))
      .subscribe(([needReloginPopup, isLoggedIn]) => {
        this.modalDialogService.closeProgressDialog();

        const needReloginState = this.authenticationService.getNeedReloginState();
        if (isLoggedIn && needReloginPopup && needReloginState !== PROGRESS_STATE.IN_PROGRESS) {
          this.modalRef = this.modalDialogService.openModal(LoginModalComponent, {
            centered: true,
            size: 'md',
            windowClass: 'top',
            beforeDismiss: async () => !this.authenticationService.getNeedReloginPopupSubject().getValue(),
          });
          this.modalRef.componentInstance.submitAction.subscribe((userCredential: UserCredential) => {
            this.store.dispatch(Login({ userCredential }));
          });

          this.authenticationService.updateNeedReloginStateSubject(PROGRESS_STATE.IN_PROGRESS);
        }

        if (!needReloginPopup && this.modalRef) {
          this.modalDialogService.closeModal(this.modalRef);
        }
      });

    if (objectPath.get(this.config, 'login.self.skin') == 'dark') {
      document.body.classList.add(objectPath.get(this.config, 'login.bgDark'));
      document.body.classList.add('theme-cyan');
    }

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);

        this.modalDialogService.closeAllModal();
        this.store.dispatch(RemoveAllToastrMessage());
        this.dataProcessingWorkspaceService.resetWorkspace();
        this.dataProcessingQueryDatasetService.resetQueryDataset();
      }
    });

    const nativeCmdItems = Array.from(this.elementRef.nativeElement.querySelectorAll('.content-query'));
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        console.log('Element dimensions changed:', entry.contentRect.width, entry.contentRect.height);
      });
    });

    this.resizeObserver.observe(document.documentElement);
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
