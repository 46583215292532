import { Injectable } from "@angular/core";
import { HOST, LOG_TYPE } from "../../consts";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private _resourceStream: { [key: string]: any } = {};
  private _eventStreamSubject$: Subject<{ logType: number; log: string }> = new Subject<{
    logType: number;
    log: string;
  }>();

  constructor() {
    this._resourceStream[LOG_TYPE.ACCESS] = null;
    this._resourceStream[LOG_TYPE.MASTER] = null;
    this._resourceStream[LOG_TYPE.SCHEDULER] = null;
  }

  async getInitLogList(logType: number, line: number, search?: string, follow?: string): Promise<void> {
    let logTypeString = '';
    switch (logType) {
      case 0:
        logTypeString = 'access';
        break;
      case 1:
        logTypeString = 'master';
        break;
      case 2:
        logTypeString = 'scheduler';
        break;
    }
    this._resourceStream[logType] = new EventSource(
      `${HOST.LOG_STREAM_LIST}/${Date.now()}?command=tail&log_type=${logTypeString}&line=${line}${
        search ? `&search=${search}` : ''
      }${follow ? `&follow=${follow}` : ''}`
    );
    this._resourceStream[logType].addEventListener('message', (messageEvent: MessageEvent) => {
      this._eventStreamSubject$.next({
        logType,
        log: messageEvent.data,
      });
    });
  }

  getEventStreamSubject(): Subject<{ logType: number; log: string }> {
    return this._eventStreamSubject$;
  }

  closeStream(logType: number) {
    this._resourceStream[logType].close();
  }
}
