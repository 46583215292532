import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

import { BrandComponent } from './brand/brand.component';
import { HeaderComponent } from './header/header.component';
import { MenuHorizontalComponent } from './menu-horizontal/menu-horizontal.component';
import { MenuVerticalComponent } from './menu-vertical/menu-vertical.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { TopbarComponent } from './topbar/topbar.component';
import { UserProfileComponent } from './topbar/component/user-profile/user-profile.component';
import { HelpComponent } from './topbar/component/help/help.component';
import { LanguageSelectorComponent } from './topbar/component/language-selector/language-selector.component';
import { NotificationComponent } from './topbar/component/notification/notification.component';
import { SettingComponent } from './topbar/component/setting/setting.component';
import { Subheader1Component } from './subheader/components/subheader1/subheader1.component';
import { Subheader2Component } from './subheader/components/subheader2/subheader2.component';
import { Subheader3Component } from './subheader/components/subheader3/subheader3.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { UpdateStatusDialogComponent } from './update-status-dialog/update-status-dialog.component';
import { SaveEntityDialogComponent } from './save-entity-dialog/save-entity-dialog.component';
import { RequestResetPasswordDialogComponent } from './requestresetpassword-dialog/requestresetpassword-dialog.component';
import { OverwriteDialogComponent } from './overwrite-dialog/overwrite-dialog.component';
import { FetchEntityDialogComponent } from './fetch-entity-dialog/fetch-entity-dialog.component';
import { DeleteEntityDialogComponent } from './delete-entity-dialog/delete-entity-dialog.component';
import { ConfirmEntityDialogComponent } from './confirm-entity-dialog/confirm-entity-dialog.component';
import { ChooseRadioDialogComponent } from './choose-radio-dialog/choose-radio-dialog.component';
import { ChangepasswordDialogComponent } from './changepassword-dialog/changepassword-dialog.component';
import { ActionNotificationComponent } from './action-notification/action-notification.component';
import { ThemeComponent } from './topbar/component/theme/theme.component';

@NgModule({
  declarations: [
    BrandComponent,
    HeaderComponent,
    MenuHorizontalComponent,
    MenuVerticalComponent,
    ActionNotificationComponent,
    SaveEntityDialogComponent,
    RequestResetPasswordDialogComponent,
    OverwriteDialogComponent,
    FetchEntityDialogComponent,
    DeleteEntityDialogComponent,
    ConfirmEntityDialogComponent,
    ChooseRadioDialogComponent,
    ChangepasswordDialogComponent,
    SubheaderComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    TopbarComponent,
    UserProfileComponent,
    HelpComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    SettingComponent,
    SplashScreenComponent,
    UpdateStatusDialogComponent,
    ThemeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    RouterModule,
    TranslateModule.forChild(),
    MatTooltipModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatSortModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTabsModule,
    MatDialogModule,
    MatSidenavModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  exports: [
    BrandComponent,
    HeaderComponent,
    MenuHorizontalComponent,
    MenuVerticalComponent,
    ActionNotificationComponent,
    SaveEntityDialogComponent,
    RequestResetPasswordDialogComponent,
    OverwriteDialogComponent,
    FetchEntityDialogComponent,
    DeleteEntityDialogComponent,
    ConfirmEntityDialogComponent,
    ChooseRadioDialogComponent,
    ChangepasswordDialogComponent,
    SubheaderComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    TopbarComponent,
    UserProfileComponent,
    NotificationComponent,
    SettingComponent,
    SplashScreenComponent,
    UpdateStatusDialogComponent,
    ThemeComponent,
  ],
})
export class LayoutModule {}
