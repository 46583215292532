import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, lastValueFrom, throwError } from 'rxjs';

import { ApiService, TranslationService } from 'src/app/libs/services';
import { ModalDialogService } from 'src/app/libs/services/common/modal-dialog.service';
import { SetToastrMessage } from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { DataProcessingDataSourceService } from 'src/app/libs/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import _ from 'lodash';

import { IDatasourceConnection } from 'src/app/libs/types/dataprocessing';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';
import { QueryStringModalComponent } from 'src/app/components/modals/query-string-modal/query-string-modal.component';
import { TranslateService } from '@ngx-translate/core';

interface IPropertyValue {
  property: string;
  value: any;
}

@Component({
  selector: 'datasource-connection-modal',
  templateUrl: './datasource-connection-modal.component.html',
  styleUrls: ['./datasource-connection-modal.component.scss'],
})
export class DatasourceConnectionModalComponent implements OnInit {
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  @Output() afterSave: EventEmitter<void> = new EventEmitter();

  private dbDriver: string = '';
  private datasourceName: string = '';
  private datasourceKey: string = '';
  private datasourceConnection: IDatasourceConnection = null;

  public isEdit: boolean = false;
  public datasourceType: string = '';
  public datasourceFormControl: FormGroup;
  public database_queryStrings: any = {};
  public datasourceConnectionData: any = {};
  public loadingApi: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private modalDialogService: ModalDialogService,
    private store: Store<AppState>,
    public dataProcessingDataSourceService: DataProcessingDataSourceService,
    public activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    let param = null;

    this.initControls();

    if (this.isEdit) {
      this.datasourceFormControl.addControl(
        'key',
        this.formBuilder.control({ value: '', disabled: false }, Validators.required)
      );

      param = {
        key: this.datasourceKey,
        c_name: this.datasourceName,
        c_type: this.datasourceType,
      };

      this.datasourceFormControl.patchValue({ ...this.datasourceConnection, ...param });

      this.database_queryStrings = this.datasourceConnection.query_string;
    } else {
      param = {
        c_type: this.datasourceType,
      };

      this.datasourceFormControl.patchValue({ ...param });
    }
  }

  initControls() {
    switch (this.dbDriver) {
      case 'sqlserver':
        this.datasourceType = 'mssql';
        break;
      case 'oci8':
        this.datasourceType = 'oracle';
        break;
      case 'go_ibm_db':
        this.datasourceType = 'db2';
        break;
      case 'mysql':
        this.datasourceType = 'mysql';
        break;
      case 'postgres':
        this.datasourceType = 'postgresql';
        break;
      default:
        this.datasourceType = this.dbDriver;
    }

    if (this.datasourceType !== 'hdfs') {
      this.datasourceFormControl = this.formBuilder.group({
        c_name: [{ value: '', disabled: this.isEdit }, Validators.required],
        c_type: [{ value: '', disabled: false }, Validators.required],
        host: [{ value: '', disabled: false }, Validators.required],
        port: [{ value: '', disabled: false }, Validators.required],
        username: [{ value: '', disabled: false }, Validators.required],
        password: [{ value: '', disabled: false }, Validators.required],
      });

      if (this.datasourceType === 'oracle') {
        this.datasourceFormControl.addControl('sid', this.formBuilder.control({ value: '', disabled: false }));
      } else {
        this.datasourceFormControl.addControl('database', this.formBuilder.control({ value: '', disabled: false }));
      }

      if (this.datasourceType === 'mssql') {
        this.datasourceFormControl.addControl('instance', this.formBuilder.control({ value: '', disabled: false }));
      }
    } else {
      this.datasourceFormControl = this.formBuilder.group({
        c_type: [{ value: '', disabled: false }, Validators.required],
        // key: [{ value: '', disabled: false }, Validators.required ],
        connection_name: [{ value: '', disabled: false }, Validators.required],
        node_name: [{ value: '', disabled: false }, Validators.required],
        port: [{ value: '', disabled: false }, Validators.required],
        token_string: [{ value: '', disabled: false }],
      });
    }
  }

  close() {
    this.datasourceFormControl.reset();

    this.onClose.emit();
  }

  prepareDatasourceForm() {
    const control = this.datasourceFormControl.controls;

    let collectedKeyValues: IDatasourceConnection = {
      c_type: control.c_type.value,
    };

    if (this.datasourceType !== 'hdfs') {
      collectedKeyValues = {
        ...collectedKeyValues,
        c_name: control.c_name.value,
        host: control.host.value,
        port: control.port.value,
        username: control.username.value,
        password: control.password.value,
      };

      if (this.datasourceType === 'oracle') {
        collectedKeyValues = {
          ...collectedKeyValues,
          sid: control.sid.value,
        };
      } else {
        collectedKeyValues = {
          ...collectedKeyValues,
          database: control.database.value,
        };
      }

      if (this.datasourceType === 'mssql') {
        collectedKeyValues = {
          ...collectedKeyValues,
          instance: control.instance.value,
        };
      }

      if (['clickhouse', 'postgresql', 'mysql', 'db2'].includes(this.datasourceType)) {
        collectedKeyValues = {
          ...collectedKeyValues,
          query_string: this.database_queryStrings,
        };
      }
    } else {
      collectedKeyValues = {
        ...collectedKeyValues,
        connection_name: control.connection_name.value,
        node_name: control.node_name.value,
        port: control.port.value,
        token_string: control.token_string.value,
      };
    }

    return collectedKeyValues;
  }

  async handleDatasourceConnectionTest(): Promise<any> {
    const datasourceFormData = this.prepareDatasourceForm();

    let result = null;
    if (this.datasourceType !== 'hdfs') {
      datasourceFormData.password = btoa(datasourceFormData.password);
      result = await lastValueFrom(
        this.apiService
          .post(`/api/dbconfig/test-config${this.isEdit ? `?key=${this.datasourceKey}` : ''}`, datasourceFormData)
          .pipe(
            catchError((err: any) => {
              this.store.dispatch(
                SetToastrMessage({
                  toastrMessage: {
                    toastrType: 'error',
                    message: `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCF')}: ${err?.message}`,
                  },
                })
              );

              this.loadingApi = false;

              return throwError(() => {});
            })
          )
      );
    } else {
      result = await lastValueFrom(
        this.apiService
          .post('/api/hdfs-connection/test', { node_name: datasourceFormData.node_name, port: datasourceFormData.port })
          .pipe(
            catchError((err: any) => {
              this.store.dispatch(
                SetToastrMessage({
                  toastrMessage: {
                    toastrType: 'error',
                    message: `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCF')}: ${err?.message}`,
                  },
                })
              );

              this.loadingApi = false;

              return throwError(() => {});
            })
          )
      );
    }

    if (result.status === 'ok' || result.status === 'success') {
      this.store.dispatch(
        SetToastrMessage({
          toastrMessage: {
            toastrType: 'info',
            message: this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCS'),
          },
        })
      );
    }
  }

  async handleDatasourceConnectionSave() {
    const controls = this.datasourceFormControl.controls;

    if (this.datasourceFormControl.invalid) {
      Object.keys(controls).forEach((controlName) => {
        controls[controlName].markAsTouched();
      });

      return;
    } else {
      const datasourceFormData = this.prepareDatasourceForm();
      datasourceFormData.password = btoa(datasourceFormData.password);

      let result = null;
      if (this.isEdit) {
        result = await lastValueFrom(
          this.apiService.put(`/api/dbconfig/update/${this.datasourceKey}`, datasourceFormData).pipe(
            catchError((err: any) => {
              this.store.dispatch(
                SetToastrMessage({
                  toastrMessage: {
                    toastrType: 'error',
                    message: `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCF')}: ${err.message}`,
                  },
                })
              );

              this.loadingApi = false;

              return throwError(() => {});
            })
          )
        );
      } else {
        const datasourceFormData = this.prepareDatasourceForm();
        datasourceFormData.password = btoa(datasourceFormData.password);

        result = await lastValueFrom(
          this.apiService.post('/api/dbconfig/add', datasourceFormData).pipe(
            catchError((err: any) => {
              this.store.dispatch(
                SetToastrMessage({
                  toastrMessage: {
                    toastrType: 'error',
                    message: `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.DBCSF')}: ${err.message}`,
                  },
                })
              );

              this.loadingApi = false;

              return throwError(() => {});
            })
          )
        );
      }

      if (result.status === 'ok' || result.status === 'success') {
        this.afterSave.emit();
        this.loadingApi = false;
        this.close();

        this.store.dispatch(
          SetToastrMessage({
            toastrMessage: {
              toastrType: 'info',
              message: this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.DBCSS'),
            },
          })
        );
      }
    }
  }

  async handleHdfsConnect() {
    const controls = this.datasourceFormControl.controls;

    if (this.datasourceFormControl.invalid) {
      Object.keys(controls).forEach((controlName) => {
        controls[controlName].markAsTouched();
      });

      return;
    } else {
      const datasourceFormData = this.prepareDatasourceForm();

      const result = await lastValueFrom(
        this.apiService.post('/api/hdfs-connection', datasourceFormData).pipe(
          catchError((err: any) => {
            this.store.dispatch(
              SetToastrMessage({
                toastrMessage: {
                  toastrType: 'error',
                  message: `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.HCE')}: ${err.message}`,
                },
              })
            );

            this.loadingApi = false;

            return throwError(() => {});
          })
        )
      );

      if (result.status && result.status === 'ok') {
        this.store.dispatch(
          SetToastrMessage({
            toastrMessage: {
              toastrType: 'info',
              message: `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.HCSA')}`,
            },
          })
        );
        this.dataProcessingDataSourceService.insertHdfsList(result.response);
        this.close();
      } else {
        this.store.dispatch(
          SetToastrMessage({
            toastrMessage: {
              toastrType: 'error',
              message: `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.HCE')}`,
            },
          })
        );
      }
    }
  }

  openModalQueryString() {
    const modalRef = this.modalDialogService.openModal(QueryStringModalComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.close.subscribe(() => modalRef.close());
    const propValues = [];
    Object.keys(this.database_queryStrings).map((key) => {
      for (const value of this.database_queryStrings[key]) {
        propValues.push({
          property: key,
          value,
        });
      }
    });
    modalRef.componentInstance.list = propValues;
    modalRef.result.then((res: IPropertyValue[]) => {
      const queryStrings = {};
      for (const obj of res) {
        if (!queryStrings[obj.property]) {
          queryStrings[obj.property] = [];
        }

        queryStrings[obj.property].push(obj.value);
      }
      this.database_queryStrings = queryStrings;
    });
  }
}
