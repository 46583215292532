import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, delay, throwError, timer } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { AppState } from 'src/app/libs/store/states';
import { SetIsError500Encountered } from 'src/app/libs/store/actions/general.actions';
import { getURLParameter } from '../../helpers/data-visualization-helper';
import { PROGRESS_STATE } from '../../consts';
import { JsonService } from './json.service';
import { SetToastrMessage } from '../../store/actions/pds/dataprocessing.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class InterceptService implements HttpInterceptor {
  private queue: any = [];
  private urlHistory: Array<string> = [];

  constructor(
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private router: Router,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.urlHistory = [this.urlHistory[1], event.url];
        setTimeout(() => {
          this.urlHistory = ['', event.url];
        }, 2000);
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.cookieService.get('token');
    request = this.addAuthorization(request, token);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 0:
            return this.handleUnexpectedError(error);
          case 403:
            this.queue.push(request);
            return this.handle403Error(request, next);
          case 401:
            return this.handle401Error(error, request, next);
          case 500:
            return this.handle500Error(error);
          case 502:
            return this.handleUnexpectedError(error);
          default:
            return throwError(() => error.error);
        }
      })
    );
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    const refresh_token = this.cookieService.get('refresh_token');
    this.addAuthorization(request, refresh_token);
    return this.authenticationService.refreshToken(refresh_token).pipe(
      switchMap((token: any) => {
        this.cookieService.set('token', token.response.access_token, { path: '/', sameSite: 'Lax' });
        this.cookieService.set('refresh_token', token.response.refresh_token, { path: '/', sameSite: 'Lax' });

        return this.intercept(request, next).pipe(
          catchError((error) => {
            if (error && error.status === 401) {
              const activeWorkingSessionStatus = this.authenticationService.getActiveWorkingSessionStatus();
              if (activeWorkingSessionStatus) {
                this.authenticationService.updateNeedReloginPopupSubject(true);
              } else {
                this.authenticationService.updateNeedReloginPageSubject(true);
              }
            }

            return throwError(() => error.error);
          })
        );
      })
    );
  }

  private handle401Error(error: any, request: HttpRequest<any>, next: HttpHandler) {
    this.cookieService.deleteAll();

    if (window.location.href.includes('app_preview')) {
      this.router.navigate(['/auth/application_login'], {
        queryParams: { slug: getURLParameter('link') },
      });

      return EMPTY;
    } else if (this.urlHistory[0] === '/home') {
      this.router.navigateByUrl('/auth/login');

      return EMPTY;
    } else {
      if (error?.error?.message.includes('incorrect username or password')) {
        return throwError(() => error.error);
      }

      this.authenticationService.updateNeedReloginSubject(true);

      const activeWorkingSessionStatus = this.authenticationService.getActiveWorkingSessionStatus();
      if (activeWorkingSessionStatus) {
        this.authenticationService.updateNeedReloginPopupSubject(true);
      } else {
        this.authenticationService.updateNeedReloginPageSubject(true);
      }

      const getNeedReloginStateSubject = () => {
        return this.authenticationService.getNeedReloginStateSubject().pipe(
          switchMap((needReloginState: number) => {
            if (needReloginState === PROGRESS_STATE.DONE) {
              this.authenticationService.updateNeedReloginStateSubject(PROGRESS_STATE.IDLE);

              return this.intercept(request, next);
            }

            if (needReloginState === PROGRESS_STATE.IDLE) {
              return EMPTY;
            }

            return timer(2000).pipe(
              delay(2000),
              switchMap(() => getNeedReloginStateSubject())
            );
          })
        );
      };

      return getNeedReloginStateSubject();
    }
  }

  private handle500Error(error) {
    this.store.dispatch(SetIsError500Encountered({ isError500Encountered: true }));
    return throwError(() => error.error);
  }

  private async handleUnexpectedError(error) {
    let err = {
      code: 502,
      message: this.translate.instant('PDS.GENERAL.GENERAL_ERROR'),
      response: null,
      status: 'error',
    };
    this.store.dispatch(SetIsError500Encountered({ isError500Encountered: true }));
    this.store.dispatch(
      SetToastrMessage({
        toastrMessage: {
          toastrType: 'error',
          message: this.translate.instant('PDS.GENERAL.GENERAL_ERROR'),
        },
      })
    );
    return throwError(() => err);
  }

  addAuthorization(httpRequest: HttpRequest<any>, token: string) {
    return (httpRequest = httpRequest.clone({
      setHeaders: {
        Authorization: httpRequest.url.includes('refresh-token') ? this.cookieService.get('refresh_token') : token,
      },
    }));
  }
}
