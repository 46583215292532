import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { NgxPermissionsModule } from 'ngx-permissions';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationEffects } from 'src/app/libs/store/effects/authentication.effects';
import {
  ApplicationService,
  AuthenticationService,
  AuthService,
  DataProcessingDataQueryService,
  DataProcessingDataSourceService,
  DataProcessingLineService,
  DataProcessingQueryDatasetService,
  DataProcessingWorkspaceService,
  DataVisualizationService,
  HeaderConfigService,
  HtmlClassService,
  InterceptService,
  LayoutConfigService,
  LayoutRefService,
  LayoutUtilsService,
  MenuConfigService,
  ModalDialogService,
  PageConfigService,
  SplashScreenService,
} from './libs/services';

import { LayoutConfig } from './libs/configs';
import { metaReducers, reducers } from './libs/store/reducers';
import { AppComponent } from './app.component';
import { RouteGuard } from './libs/guards/route.guard';
import { LayoutModule } from './layouts/layout.module';

import { LoginModalComponent } from './components/modals';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { LogService } from './libs/services/pds/log.service';

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

@NgModule({
  declarations: [AppComponent, LoginModalComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EffectsModule.forFeature([AuthenticationEffects]),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImgFallbackModule,
    NgxPermissionsModule.forRoot(),
    LayoutModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    TranslateModule.forRoot(),
    EffectsModule.forRoot([]),
    MonacoEditorModule.forRoot(),
  ],
  providers: [
    RouteGuard,
    AuthService,
    ApplicationService,
    AuthenticationService,
    DataProcessingDataQueryService,
    DataProcessingDataSourceService,
    DataProcessingLineService,
    DataProcessingWorkspaceService,
    DataProcessingQueryDatasetService,
    DataVisualizationService,
    HeaderConfigService,
    HtmlClassService,
    LayoutConfigService,
    LayoutRefService,
    LayoutUtilsService,
    LogService,
    MenuConfigService,
    ModalDialogService,
    PageConfigService,
    SplashScreenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
