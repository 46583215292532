import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from 'src/app/layouts/layout.module';
import { AuthGuard } from 'src/app/libs/guards/auth.guard';
import { BaseLayoutComponent } from './layouts/base/base-layout.component';
import { AdminGuard } from './libs/guards/admin.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then((mod) => mod.HomeModule),
  },
  {
    path: 'pds',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pds/pds.module').then((m) => m.PdsModule),
  },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then((mod) => mod.AuthModule) },
  {
    path: 'pdsshare',
    loadChildren: () => import('./pages/pds/pds.module').then((mod) => mod.PdsModule),
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'pds', loadChildren: () => import('./pages/pds/pds.module').then((m) => m.PdsModule) },
      {
        path: 'user-management',
        loadChildren: () =>
          import('src/app/pages/user-management/user-management.module').then((m) => m.UserManagementModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('src/app/pages/notifications/notification.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'log-service',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('src/app/pages/log-service/log-service.module').then((m) => m.LogServiceModule),
      },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  declarations: [BaseLayoutComponent],
  imports: [CommonModule, LayoutModule, RouterModule.forRoot(routes, { useHash: true })],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
