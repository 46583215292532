import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './api.service';

import { rest_api } from 'src/app/libs/configs/restapi';
import { AppState } from 'src/app/libs/store/states';
import { ResetAuthentication } from 'src/app/libs/store/actions/authentication.actions';
import { PROGRESS_STATE } from '../../consts';
import { DataProcessingWorkspaceService } from '../pds/dataprocessing/workspace.service';
import { DataVisualizationService } from '../pds/datavisualization/datavisualization.service';

@Injectable()
export class AuthenticationService {
  private _needReloginSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _needReloginPageSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _needReloginPopupSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _needReloginStateSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(PROGRESS_STATE.IDLE);

  constructor(
    private dataProcessingWorkspaceService: DataProcessingWorkspaceService,
    private dataVisualizationService: DataVisualizationService,
    private store: Store<AppState>,
    private apiService: ApiService,
    private http: HttpClient,
  ) {}

  getNeedReloginSubject(): BehaviorSubject<any> {
    return this._needReloginSubject$;
  }

  getNeedReloginPageSubject(): BehaviorSubject<any> {
    return this._needReloginPageSubject$;
  }

  getNeedReloginPopupSubject(): BehaviorSubject<any> {
    return this._needReloginPopupSubject$;
  }

  getNeedReloginStateSubject(): BehaviorSubject<any> {
    return this._needReloginStateSubject$;
  }

  getNeedReloginState(): number {
    return this._needReloginStateSubject$.getValue();
  }

  updateNeedReloginSubject(state: boolean): void {
    this._needReloginSubject$.next(state);
  }

  updateNeedReloginPageSubject(state: boolean): void {
    this._needReloginPageSubject$.next(state);
  }

  updateNeedReloginPopupSubject(state: boolean): void {
    this._needReloginPopupSubject$.next(state);
  }

  updateNeedReloginStateSubject(status: number): void {
    this._needReloginStateSubject$.next(status);
  }

  getActiveWorkingSessionStatus(): boolean {
    const dataVizualizationActiveSessionExists = this.dataVisualizationService.getActiveWorkingSessionStatus();
    const dataProcessingActiveSessionExists = this.dataProcessingWorkspaceService.getActiveWorkingSessionStatus();
    return dataVizualizationActiveSessionExists || dataProcessingActiveSessionExists;
  }

  resetNeedRelogin() {
    this._needReloginSubject$.next(false);
    this._needReloginPageSubject$.next(false);
    this._needReloginPopupSubject$.next(false);
  }

  login(userCredential: UserCredential): Observable<any> {
    return this.apiService.post(rest_api.AUTH_LOGIN, userCredential);
  }

  logout(): Observable<any> {
    return this.apiService.post(rest_api.AUTH_LOGOUT);
  }

  refreshToken(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
    };
    return this.http.post<any[]>(rest_api.REFRESH_TOKEN, {}, httpOptions);
  }

  getCaptcha(): Observable<any> {
    return this.apiService.get(rest_api.CAPTCHA);
  }

  resetAuthentication() {
    this.store.dispatch(ResetAuthentication());
  }
}
