import { Injectable, QueryList } from '@angular/core';
import { Workspace, WorkspaceState } from 'src/app/libs/models/query/workspace.model';
import { ApiService } from '../../common/api.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';
import { SetToastrMessage } from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { DataProcessingQueryDatasetService } from './query-dataset.service';
import { IServiceFunctionResult } from 'src/app/libs/types/dataprocessing';
import { DATAPROCESSING_API_PATH } from 'src/app/libs/consts';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CmdIface } from 'src/app/libs/models/query/command.model';
import { CommandItemComponent } from 'src/app/components/workspaces/data-processing-workspace/components/command-item/command-item.component';
import { DataProcessingLineService } from './line.service';
import { PanZoomAPI, PanZoomConfig, PanZoomConfigOptions, PanZoomModel } from 'ngx-panzoom';
import _, { reject } from 'lodash';
import { SlotName } from '../../../models/query/query.model';
import { SelectCmd } from '../../../models/query/select.model';
import { ModalDialogService } from '../../common/modal-dialog.service';
import { JsonService } from '../../common/json.service';
import { TranslateService } from '@ngx-translate/core';

const panZoomConfigOptions: PanZoomConfigOptions = {
  acceleratePan: false,
  zoomLevels: 3.5,
  initialZoomLevel: 2,
  scalePerZoomLevel: 2,
  initialPanX: 0,
  initialPanY: 0,
  zoomStepDuration: 0.2,
  freeMouseWheelFactor: 0.08,
  zoomToFitZoomLevelFactor: 0.95,
  dragMouseButton: 'left',
  zoomOnDoubleClick: false,
  zoomOnMouseWheel: false,
  zoomButtonIncrement: 1,
  freeMouseWheel: false,
  noDragFromElementClass: 'pan-zoom-frame',
};

@Injectable({
  providedIn: 'root',
})
export class DataProcessingWorkspaceService {
  private _workspace: Workspace;
  private _cmdIndex: number = 0;
  private _commandItems: QueryList<CommandItemComponent>;
  private _panZoomAPI: PanZoomAPI;
  private _panZoomConfig: PanZoomConfig = new PanZoomConfig(panZoomConfigOptions);
  private _panZoomModel: PanZoomModel;
  private _scaleValue: number = 1;
  private _workspaceHeight: number = 300;

  private _activeWorkingSessionStatusSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _cmdMapLengthSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _itemFocusedSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _sourceSelectionItemsSubject$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private _triggerRepositionSubject$: BehaviorSubject<{ trigger: boolean; delay: number }> = new BehaviorSubject({ trigger: false, delay: 0 });

  constructor(
    private apiService: ApiService,
    private dataProcessingLineService: DataProcessingLineService,
    private dataProcessingQueryDatasetService: DataProcessingQueryDatasetService,
    private modalDialogService: ModalDialogService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {
    combineLatest([this.dataProcessingQueryDatasetService.getPqlStringSubject(), this._cmdMapLengthSubject$]).subscribe(
      ([pqlString, cmdMapLength]) => {
        if (pqlString || cmdMapLength > 0) {
          this._activeWorkingSessionStatusSubject$.next(true);
        } else {
          this._activeWorkingSessionStatusSubject$.next(false);
        }
      }
    );
  }

  /************************************/
  /*       ALL PUBLIC FUNCTION        */
  /************************************/
  initPanZoom() {
    this._panZoomConfig.modelChanged.subscribe((model: PanZoomModel) => this._onPanzoomModelChanged(model));

    this._panZoomConfig.api.subscribe((api: PanZoomAPI) => (this._panZoomAPI = api));
  }

  getCmdMap(nativeId: string) {
    return this._workspace.cmdMap[nativeId];
  }

  getAllCmdMap() {
    return this._workspace.cmdMap;
  }

  getItemFocusedSubject(): BehaviorSubject<any> {
    return this._itemFocusedSubject$;
  }

  getItemFocused(): any {
    return this._itemFocusedSubject$.getValue();
  }

  getPanZoomConfig(): PanZoomConfig {
    return this._panZoomConfig;
  }

  getPanZoomModel(): PanZoomModel {
    return this._panZoomModel;
  }

  getScaleValue(): number {
    return this._scaleValue;
  }

  getSourceSelectionItemsSubject(): BehaviorSubject<string[]> {
    return this._sourceSelectionItemsSubject$;
  }

  getSourceSelectionItems(): string[] {
    return this._sourceSelectionItemsSubject$.getValue();
  }

  getTriggerRepositionSubject(): BehaviorSubject<{ trigger: boolean; delay: number }> {
    return this._triggerRepositionSubject$;
  }

  setTriggerReposition(trigger: boolean, delay: number = 0): void {
    this._triggerRepositionSubject$.next({ trigger, delay });
  }

  setCommandItems(commandItems: QueryList<CommandItemComponent>) {
    if (commandItems.length === 0) this._cmdIndex = 0;
    this._commandItems = commandItems;
  }

  setItemFocused(item: any): void {
    this._itemFocusedSubject$.next(item);
  }

  setScaleValue(scale: number): void {
    this._scaleValue = scale;
  }

  setSourceSelectionItems(items: any): void {
    if (!items || (items && Object.keys(items).length === 0)) return;

    this._sourceSelectionItemsSubject$.next(items);
  }

  loadFromPayload(payload: { nodes: any; model: any }) {
    this._workspace.LoadFromPayload(payload);
  }

  newWorkspace(model?: any): void {
    this._workspace = model ? new Workspace(model) : new Workspace();
    this._cmdIndex = 0;
    this._cmdMapLengthSubject$.next(0);
  }

  async workspaceNewCommand(nodeName: string, data?: any): Promise<void> {
    await new Promise<void>((resolve) => {
      let cmd: CmdIface = null;

      switch (nodeName) {
        case 'CORRELATEREF':
          cmd = this._workspace.NewCorrelateRefCmd();
          break;
        case 'SELECTDB':
          cmd = this._workspace.NewSelectDbCmd();
          break;
        default:
          cmd = this._workspace[`New${nodeName[0]?.toUpperCase() + nodeName?.slice(1).toLowerCase()}Cmd`]();
      }

      this._cmdIndex++;
      const cmdMapLength = Object.keys(this._workspace?.cmdMap ? this._workspace?.cmdMap : [])?.length ?? 0;
      this._cmdMapLengthSubject$.next(cmdMapLength);
      const pos = this._getFreeCommandItemPosition();
      cmd?.SetCoordinate(pos.x, pos.y);

      if (data) {
        const filetype: any = this.changFiletype(data.fileType);
        if (data.name === 'select-db') {
          if (data.item.isTable) {
            cmd.SetProp('connection', data.item.connectionName);
            cmd.SetProp('select_db_clause_a', '*');
            cmd.SetProp('select_db_clause_b', data.item.id);
          } else {
            cmd.SetProp('connection', data.item.connectionName);
            cmd.SetProp('select_db_clause_a', data.item.id);
            cmd.SetProp('select_db_clause_b', data.item.tableName);
          }
        }
        if (data.name === 'select') {
          (cmd as SelectCmd).ChangeFromType(filetype);

          cmd.SetProp(`from_${filetype.toLowerCase()}.path`, data.pathFile);
          cmd.SetProp('from_type', filetype);
          cmd.SetProp('path', data.pathFile);
        }
        if (data.name === 'config') {
          cmd.SetProp('type', filetype);
          cmd.SetProp('separator', ',');
          cmd.SetProp('path', data.pathFile);
        }
        if (data.name === 'search') {
          if (['search_from_file_csv', 'search_from_file_csv_gz'].includes(data.type)) {
            if (data.type === 'search_from_file_csv_gz') {
              cmd.SetProp('is_gz', true);
            }
            cmd.SetProp('is_csv', true);
            cmd.SetProp('is_folder', false);
            cmd.SetProp('csv_separator', ',');
            cmd.SetProp('include_header', true);
            cmd.SetProp('paths', [data.pathFile]);
          } else {
            if (data.searchType === 'FILE') {
              cmd.SetProp('is_file', true);
              cmd.SetProp('is_folder', false);
            }

            if (data.searchType === 'FOLDER') {
              cmd.SetProp('is_file', false);
              cmd.SetProp('is_folder', true);
            }
            cmd.SetProp('separator', ',');
            cmd.SetProp('paths', [data.pathFile]);
          }
        }
      }

      this.modalDialogService.closeProgressDialog();

      resolve();
    });
  }

  changFiletype(filetype: string) {
    switch (filetype) {
      case 'CSV GZIP':
        return 'CSV GZ';
      case 'JSON GZIP':
        return 'JSON GZ';
      case 'GZIP':
        return 'GZ';
      default:
        return filetype;
    }
  }

  hasWorkspace(): boolean {
    return !!this._workspace;
  }

  hasQueryCommand(): boolean {
    return !!this._workspace?.cmdMap && Object.keys(this._workspace?.cmdMap).length > 0;
  }

  getActiveWorkingSessionStatus(): boolean {
    return this._activeWorkingSessionStatusSubject$.getValue();
  }

  getWorkspaceGeneratedState(): WorkspaceState {
    return this._workspace.GenerateState();
  }

  updateActiveWorkingSessionStatusSubject(status: boolean): void {
    this._activeWorkingSessionStatusSubject$.next(status);
  }

  async parse(): Promise<IServiceFunctionResult> {
    const pqlString = this.dataProcessingQueryDatasetService.getPqlString();

    if (!pqlString) return;

    try {
      const strRegex = new RegExp(/\n/gi);
      const strippedPqlString = pqlString.replace(strRegex, ' ');

      const result: ApiResult = await new Promise<ApiResult>((resolve, reject) => {
        this.apiService.post(DATAPROCESSING_API_PATH.WORKSPACE_PARSE, { pql: strippedPqlString }, true).subscribe({
          next: (res) => resolve(res),
          error: (err) => reject(err),
        });
      });

      if (result?.response) {
        return {
          success: true,
          payload: result.response,
        } as IServiceFunctionResult;
      }
    } catch (e) {
      return {
        success: false,
        payload: e?.message ?? this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.E'),
      } as IServiceFunctionResult;
    }
  }

  async render(): Promise<IServiceFunctionResult> {
    const payload = this._workspace.GetPayload();

    try {
      const result: ApiResult = await new Promise<ApiResult>((resolve, reject) => {
        this.apiService.post(DATAPROCESSING_API_PATH.WORKSPACE_RENDER, payload).subscribe({
          next: (res) => resolve(res),
          error: (err) => reject(err),
        });
      });

      if (result?.response) {
        return {
          success: true,
          payload: result.response,
        } as IServiceFunctionResult;
      }
    } catch (e) {
      return {
        success: false,
        payload: e.message,
      } as IServiceFunctionResult;
    }
  }

  removeCmdMap(cmdId: string) {
    this._workspace.RemoveCmd(cmdId);
    this._cmdIndex--;
    const cmdMapLength = Object.keys(this._workspace?.cmdMap ? this._workspace?.cmdMap : [])?.length ?? 0;
    this._cmdMapLengthSubject$.next(cmdMapLength);
  }

  removeAllCmdMap() {
    this._workspace.RemoveCmdAll();
    this._cmdIndex = 0;
    this._cmdMapLengthSubject$.next(0);
  }

  async resetNodesFormation(nativeCmdItems: HTMLElement[]) {
    for (const cmdItemComp of nativeCmdItems) {
      const nativeId = (cmdItemComp as HTMLDivElement).getAttribute('id');
      (cmdItemComp as HTMLDivElement).style.transform = `translate3d(${this.getCmdMap(nativeId).GetCoordinate().x}px, ${
        this.getCmdMap(nativeId).GetCoordinate().y
      }px, 0px)`;
    }

    await new Promise<void>((resolve) => setTimeout(() => resolve(), 100));
    this.dataProcessingLineService.updateAllLinePosition();
  }

  resetWorkspace() {
    this._workspace = null;
    this._activeWorkingSessionStatusSubject$.next(false);
    this._cmdIndex = 0;
    this._cmdMapLengthSubject$.next(0);
  }

  resetPanZoomView() {
    this._panZoomAPI.resetView();
  }

  /************************************/
  /*  ALL PRIVATE INTERNAL FUNCTION   */
  /************************************/
  private _getFreeCommandItemPosition(): { x: number; y: number } {
    let farestX = 0;
    let farestY = 0;

    if (this._commandItems?.length > 0) {
      // get farest X
      this._commandItems.forEach((_commandItem: CommandItemComponent) => {
        if (farestX < _commandItem?.cmdItem?.GetCoordinate().x) {
          farestX = _commandItem?.cmdItem?.GetCoordinate().x;
        }
      });

      // get farest Y, in respect to X
      this._commandItems.forEach((_commandItem: CommandItemComponent) => {
        if (_commandItem?.cmdItem?.GetCoordinate().x >= farestX && farestY < _commandItem?.cmdItem?.GetCoordinate().y) {
          farestY = _commandItem?.cmdItem?.GetCoordinate().y;
        }

        if (farestY > this._workspaceHeight - 80) {
          farestX += 80;
          farestY = 80;
          this._cmdIndex = 1;
        } else {
          farestY = 80 * (this._cmdIndex - 1);
        }
      });
    }

    return { x: farestX, y: farestY };
  }

  private _onPanzoomModelChanged(model: PanZoomModel): void {
    this._panZoomModel = _.cloneDeep(model);

    if (model.isPanning) {
      this.dataProcessingLineService.updateAllLinePosition();
    }
  }

  triggerSetupOnScreenResize(obj: { dimension: { width: number; height: number } }): void {
    this._workspaceHeight = obj?.dimension?.height;
  }
}
