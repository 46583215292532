<div>
  <div class="modal-header">
    <h5 class="title f-16">
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.SERIES_API.AC' | translate }} Parameter Connection
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="_close()" style="position: unset">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row clearfix">
      <div class="col-md-4 form-control-label">
        <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.SERIES_API.PR' | translate }}</label>
      </div>
      <div class="col-md-4 form-control-label">
        <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.SERIES_API.V' | translate }}</label>
      </div>
      <div class="col-md-2 form-control-label">
        <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.SERIES_API.R' | translate }}</label>
      </div>
    </div>
    <div class="row clearfix" *ngFor="let item of api_queryStrings; let i = index">
      <div class="col-md-4">
        <div class="input-group">
          <input type="text" class="form-control" [(ngModel)]="api_queryStrings[i].property" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="input-group">
          <input type="text" class="form-control" [(ngModel)]="api_queryStrings[i].value" />
        </div>
      </div>
      <!-- for deleteting -->
      <div class="col-md-2">
        <a type="button" class="p-1" (click)="onDelQueryStringsApi(i)"><i class="ti-trash text-danger"></i></a>
      </div>
    </div>
    <br />
    <div class="row clearfix">
      <div class="col-md-6">
        <button type="button" class="btn btn-info btn-outline btn-sm mx-0" (click)="onAddQueryStringsApi()">
          {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.SERIES_API.ANQ' | translate }}
          <i class="zmdi zmdi-plus f-12 ml-1"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-neutral btn-simple btn-sm close px-0" type="button" data-dismiss="modal" (click)="_close()">
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.C' | translate }}
    </button>
  </div>
</div>
