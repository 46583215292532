 import { axisFormater, generateLegend, CHART_CONSTANTS, yAxisObj, xAxisObj   } from '.';
import { convert_metric_to_verbose } from 'src/app/libs/helpers/utility';
import { E } from '@angular/cdk/keycodes';
import { reformatNumber } from 'src/app/libs/helpers/data-visualization-helper';
import * as formatNumberIdFile from 'src/assets/data/formatNumberID.json';
import { generateFormatDate } from './helperLine';
import moment from 'moment';


declare var d3: any;
const formatLabel = (data: any, counts: any) => {
  return {
    // show: data.form_data.show_bar_value || counts ? true : false,
    show: false,
    position:
      data.form_data.bar_stacked && !counts ? '' : data.form_data.viz_type === 'horizontal_bar' ? 'right' : 'top',
    formatter: (params: any) => {
      const count = counts[params.dataIndex];
      const formatNumbber = data.form_data.format_number_tooltips;
      return counts
        ? axisFormater(count, data, formatNumbber)
        : params.value === 0 && data.form_data.bar_stacked
        ? ''
        : axisFormater(params.value, data, formatNumbber);
    },
    textStyle: {
      color: data.form_data.bar_stacked || counts ? null : 'inherit',
      fontSize: '9px',
    },
  };
};

const createSerie = (type: string, name: string, data: any, stack: any, label?: any, key?: string, color?: string) => ({
  type,
  name,
  data,
  stack,
  label,
  key,
  itemStyle: {
    color,
  },
});

const getMetricCounts = (metrics: any, data: any) => {
  return metrics.map((metric: string) => {
    let countItem = data.key.map((item: any) => 0);

    if (data.value[metric]) {
      if (!data.value[metric][0]) {
        Object.keys(data.value[metric]).map((group, index) => {
          countItem = data.value[metric][group].map((value: any, i: number) => countItem[i] + value);
        });
      } else countItem = data.value[metric].map((value: any, i: number) => countItem[i] + value);
    }

    return { metric, data: countItem };
  });
};

const helperPickColor = (raw, selectedValue, pickColor, serieName, item, index) => {
  const findIndex =
    raw.form_data.style_tooltips === 'axis' &&
    raw.data.key.findIndex((item: string) => {
      return item === selectedValue;
    });
  let forSelected = `${serieName}=${item}`;
  if (raw.form_data.style_tooltips === 'item') {
    return selectedValue !== '' && selectedValue !== forSelected ? `${pickColor}26` : pickColor;
  } else if (raw.form_data.style_tooltips === 'axis') {
    return findIndex >= 0 && index !== findIndex ? `${pickColor}26` : pickColor;
  }
};

const getSerieColor = (color, raw, serieName, index, idxGroup?) => {
  if (raw.form_data.choose_pallete === 'custom_pallete' ){
    if(raw?.form_data?.colorpickers[2].colorpicker ){
       return raw?.form_data?.colorpickers[2].colorpicker 
    } else {
        return '#808080';
    }
  }else{
    return idxGroup ? color[idxGroup % color.length] : color[index];
  }
};

const mappingDataToOptions = (raw: any, explore: any, color, selectedValue) => {
  let legend = [];
  let series = [];

  let metricList = raw.form_data
    ? raw.form_data.line_metric
      ? [...raw.form_data.metrics, raw.form_data.line_metric]
      : raw.form_data.metrics
    : [];
  const key = raw.form_data.groupby ? raw.form_data.groupby[0] : '';
  metricList.map((metric: any, index: number) => {
    const { metric_values, metricName, serieType } = findMetricValue(raw, explore, metricList, metric, index);
    const stack = raw.form_data.bar_stacked && serieType === 'bar' ? 'bar' : false;
    const label = formatLabel(raw, false);
    if (metric_values) {
      if (metric_values[0] === undefined) {
        Object.keys(metric_values).map((group, idxGroup) => {
          const serieName = `${metricName} - ${group}::${serieType}`;
          let data = metric_values[group].map((item, idxVal) => {
            let pickColor = getSerieColor(color, raw, serieName, index, idxGroup);
            return {
              value: item,
              selected: `${serieName}=${item}`,
              itemStyle: {
                color: helperPickColor(raw, selectedValue, pickColor, serieName, item, idxVal),
              },
            };
          });
          const serieData = createSerie(serieType, serieName, data, stack, label, key);
          legend.push(serieName);
          series.push(serieData);
        });
      } else {
        const serieName = `${metricName}::${serieType}`;
        let pickColor = getSerieColor(color, raw, serieName, index);
        let data = metric_values.map((item, i) => {
          return {
            value: item,
            selected: `${raw.data.key[i]}-${serieName}=${item}`,
            itemStyle: {
              color:
                selectedValue !== '' && selectedValue !== `${raw.data.key[i]}-${serieName}=${item}`
                  ? `${pickColor}26`
                  : pickColor,
            },
          };
        });

        if (raw.form_data.viz_type === 'horizontal_bar' && raw.form_data.order_desc) {
          data = data.sort((a, b) => a.value - b.value);
        } else if (raw.form_data.viz_type === 'horizontal_bar' && !raw.form_data.order_desc) {
          data = data.sort((a, b) => b.value - a.value);
        }

        const serieData = createSerie(serieType, serieName, data, stack, label, key, pickColor);
        legend.push(serieName);
        series.push(serieData);
      }
    }

    if (raw.data.value.CONST) {
      const serieData = createSerie('line', 'CONST::line', raw.data.value.CONST, false, '', key);
      legend.push('CONST::line');
      series.push(serieData);
    }
  });
  if (raw.form_data.count_stacked && raw.form_data.bar_stacked) {
    const rawData = [];
    const totalData = [];

    getMetricCounts(raw.form_data.metrics, raw.data).map((item: any) => {
      rawData.push(item.data);
    });
    for (let i = 0; i < rawData[0].length; ++i) {
      let sum = 0;
      for (let j = 0; j < rawData.length; ++j) {
        sum += rawData[j][i];
      }
      totalData.push(sum);
    }
    series[series.length - 1].label = {
      show: false,
      position: 'top',
      fontSize: 10,
      formatter: (params: any) => totalData[params.dataIndex].toFixed(1)
    }
  }

  return { series, legend };
};

const getTooltipItem = (seriesName: string, data: any, params: any, explore: any, style: string) => {
  const metricName = seriesName.split(' - ')[0];
  const groupName = seriesName.replace(`${metricName} - `, '');
  const tooltipStrip = data.form_data.tooltips[0] ? '' : '<br>-';
  let tooltip = style === 'item' ? `<b>${params.name}</b>${tooltipStrip}` : '';

  tooltip += `<br>${params.marker} ${seriesName}
        : ${axisFormater(params.data.value, data, data.form_data.format_number_tooltips)}`;

  return tooltip;
};

const formatTooltip = (params: any, data: any, explore: any, style: string) => {
  if (style === 'item') {
    const seriesName = params.seriesName.split('::')[0];
    return getTooltipItem(seriesName, data, params, explore, style);
  } else {
    const tooltipStrip = data.form_data.tooltips[0] ? '' : '<br>-';
    let tooltips = `<b>${params[0].name}</b>${tooltipStrip}`;

    for (const row of params) {
      const seriesName = row.seriesName.split('::')[0];

      const tooltip = getTooltipItem(seriesName, data, row, explore, style);
      tooltips += `${tooltip}`;
    }

    return tooltips;
  }
};


const scaleValues = (value: any, yLogScale: boolean, raw: any) => {
  return yLogScale ? value.map((item: number) => Math.log10(item)) : generateValueLine(raw, value);
};


const generateValueLine = (item, data) => {
  let value = data;
  let modifArr = [];
  if (item.form_data.y_axis_bounds_max) {
    data.map((val) => {
      if (item.form_data.y_axis_bounds_max) {
        let changeValue = val > item.form_data.y_axis_bounds_max ? item.form_data.y_axis_bounds_max : val;
        modifArr.push(changeValue);
      }
    });
    return modifArr;
  }
  return value;
};


const originValues = (value: any, yLogScale: boolean) => {
  return yLogScale ? value.map((item: number) => Math.log10(item)) : value;
};

const getSerieColorLine = (color, raw, serieName) => {
  const colorObject = raw.form_data.colorpickers.find((obj) => obj.entity === serieName);
  if (raw.form_data.choose_pallete === 'custom_pallete') {
    if (colorObject) return colorObject.colorpicker;
    else return '#808080';
  } else return color;
};

const sortValues = (values: Array<any>, formData: any) => {
  return formData.order_desc ? [...values].reverse() : values;
};


const mappingDataLine = (raw: any, explore: any, color: Array<any>, selectedValue: string, filterId: any) => {
  const formatdate = generateFormatDate(raw.form_data.time_grain_sqla, raw.form_data.x_axis_format);
  const formattedDate = raw.data.key.map((item: any) => {
    const x = item * 1000;
    const date = moment(x).format(formatdate);
    return date;
  });

  const firstMetric = raw.form_data.metrics ? raw.form_data.metrics[0] || false : false;
  const isDualAxis = raw.form_data.viz_type === 'dual_line';
  const yLogScale = raw.form_data.y_log_scale;

  let metrics = raw.form_data.metrics;
  let legends = [];
  let minValue: number;

  if (isDualAxis) metrics = [raw.form_data.metric, raw.form_data.metric_2];

  let series = [];
  if (raw.data.value) {
    series =
      raw.form_data.groupby && raw.form_data.groupby[0]
        ? Object.keys(raw.data.value[firstMetric]).map((group, index) => {
            let scaledValues = scaleValues(raw.data.value[firstMetric][group], yLogScale, raw);
            const stack = raw.form_data.area_chart && raw.form_data.stack_area_chart ? 'total' : false;
            let originVal = originValues(raw.data.value[firstMetric][group], yLogScale);
            legends.push(group);
            let pickColor = getSerieColorLine(color[index], raw, group);
            let select = selectedValue.split('=')[1];
            let indexByGroup = null;

            if (select) {
              indexByGroup = Object.keys(raw.data.value[firstMetric]).findIndex(
                (data) => data === selectedValue.split('=')[0]
              );
            }
            return {
              name: group,
              type: 'line',
              smooth: raw.form_data.line_interpolation === 'smooth' && true,
              // smooth : true,
              step: raw.form_data.line_interpolation === 'step-line' && true,
              data: sortValues(scaledValues, raw.form_data),
              connectNulls: true,
              symbolSize: 7,
              symbol: 'circle',
              showSymbol: selectedValue.split('=')[0] === '' ? raw.form_data.show_markers || isDualAxis : true,
              areaStyle: raw.form_data.area_chart || null,
              stack,
              origin: sortValues(originVal, raw.form_data),
              lineStyle: {
                color: selectedValue.split('=')[0] !== '' ? `${pickColor}26` : pickColor,
              },
              itemStyle: {
                color:
                  selectedValue.split('=')[0] !== '' && filterId === raw.form_data.slice_id
                    ? `${pickColor}26`
                    : pickColor,
              },
              markPoint: {
                symbol: 'circle',
                symbolSize: [10, 10],
                data: [
                  {
                    coord: select ? [Number(select.split(',')[0]), Number(select.split(',')[1])] : [],
                    itemStyle: {
                      color: indexByGroup !== null ? color[indexByGroup] : '',
                    },
                  },
                ],
              },
            };
          })
        : metrics.map((metric: string, index: number) => {
            const metricName = convert_metric_to_verbose(metric, explore);
            let scaledValues = scaleValues(raw.data.value[metric], yLogScale, raw);
            const stack = raw.form_data.area_chart && raw.form_data.stack_area_chart ? 'total' : false;
            legends.push(metricName);
            let pickColor = getSerieColorLine(color[index], raw, metricName);
            let select = selectedValue.split('=')[1];
            return {
              name: metricName,
              type: 'line',
              smooth: raw.form_data.line_interpolation === 'smooth' && true,
                // smooth:  true,
              step: raw.form_data.line_interpolation === 'step-line' && true,
              data: sortValues(scaledValues, raw.form_data),
              connectNulls: true,
              symbolSize: 7,
              symbol: 'circle',
              yAxisIndex: index === 1 && isDualAxis ? 1 : 0,
              showSymbol: selectedValue.split('=')[0] === '' ? raw.form_data.show_markers || isDualAxis : true,
              areaStyle: raw.form_data.area_chart || null,
              stack,
              lineStyle: {
                color: selectedValue.split('=')[0] !== '' ? `${pickColor}26` : pickColor,
              },
              itemStyle: {
                color:
                  selectedValue.split('=')[0] !== '' && filterId === raw.form_data.slice_id
                    ? `${pickColor}26`
                    : pickColor,
              },
              markPoint: {
                symbol: 'circle',
                symbolSize: [10, 10],
                data: [
                  {
                    coord: select ? [Number(select.split(',')[0]), Number(select.split(',')[1])] : [],
                    itemStyle: {
                      color: select ? pickColor : '',
                    },
                  },
                ],
              },
            };
          });
  }

  return {
    key: raw.form_data.order_desc ? formattedDate.reverse() : formattedDate,
    series,
    legends,
    minValue,
  };
};



export const setConfigSparkline = (data: any, explore, selectedValue,filterId?) => {
  let formaterNumber = formatNumberIdFile;
  let locale = data?.form_data?.format_number_id ? 'ID' : 'EN';
  d3.formatDefaultLocale(formaterNumber[locale]);

  let value = 0;
  if (data?.data?.data.length > 0) {
    data.data.data[0].map((data) => {
      value = data;
    });
}

let chartOption, yAxis, yAxisLine,xAxis, yAxisData, chartData, form_data;

if(data.form_data.sparkline_type === 'bar'){
  const color = CHART_CONSTANTS.general.collorPalette[data.form_data.color_scheme] || [];
   chartOption = mappingDataToOptions(data, explore, color, selectedValue);
   yAxis = {
    show : false,
    name: data.form_data.y_axis_label || '',
    axisLabel: {
      fontSize: 10,
      formatter: (value: any) => axisFormater(value, data, data.form_data.y_axis_format),
    },
    ...yAxisObj,
  };

   yAxisLine = {
    name: data.form_data.y_axis_line || '',
    min:
      data.form_data.y_axis_bounds_min && data.form_data.y_axis_bounds_min !== ''
        ? data.form_data.y_axis_bounds_min
        : 0 || 0,
    max:
      data.form_data.y_axis_bounds_max && data.form_data.y_axis_bounds_max !== ''
        ? data.form_data.y_axis_bounds_max
        : 100 || 100,
    axisLabel: {
      formatter: (value: number) => axisFormater(value, data, data.form_data.y_axis_2_format),
    },
    // show: data.form_data.show_dual_axis_line,
    show : false,
    position: 'right',
    ...yAxisObj,
  };

  let tempData = [];
  if (data.form_data.viz_type === 'horizontal_bar') {
    tempData = data.data.key.map((_, index, array) => array[array.length - 1 - index]);
  }

   xAxis = {
    show : false,
    data: data.form_data.viz_type === 'horizontal_bar' ? tempData : data.data.key,
    name: data.form_data?.x_axis_label || '',
    axisLabel: {
      textStyle: { fontSize: 10 },
      rotate: Number(data.form_data.rotate_axis),
    },
    ...xAxisObj,
  };
} else if(data.form_data.sparkline_type === 'line'){
   form_data = data.form_data;
  const color = CHART_CONSTANTS.general.collorPalette[form_data.color_scheme] || [];
   chartData = mappingDataLine(data, explore, color, selectedValue, filterId);
  const metricName1 =
    form_data.metrics && !data.form_data.metrics[1] ? convert_metric_to_verbose(form_data.metrics[0], explore) : false;

  const metricName2 = form_data.metric ? convert_metric_to_verbose(form_data.metric, explore) : false;
  const metricName3 = form_data.metric_2 ? convert_metric_to_verbose(form_data.metric_2, explore) : false;

  yAxisData = {
    show : false,
    axisLabel: {
      fontSize: 10,
      formatter: (value: any) => (value !== 0 ? axisFormater(value, data, data.form_data.y_axis_format) : 0),
    },
    name: form_data.y_axis_label || '',
    ...yAxisObj,
  };

   yAxis =  {
    show : false,
    ...yAxisData,
    min: form_data.y_axis_bounds_min || null,
    max: form_data.y_axis_bounds_max || null,
  };

}
 

   const title = { 
          text : reformatNumber(value, data?.form_data?.y_axis_format, data, d3) || 0,
          toolbox: {
            feature: {},
          },
          top : '10%',
          textStyle: {
             fontSize: data?.form_data?.zoomsize ? `${data?.form_data?.zoomsize}rem` : `${data?.form_data?.subheaderfontsize}rem` ,
             color: data?.form_data?.colorpickers.length > 0 ? data?.form_data?.colorpickers[0].colorpicker : '#888888',
          },
          // label: data.data.subheader,
          subtext : data?.data?.subheader|| data?.form_data?.subheader, 
          subtextStyle:{
            fontSize: `${data?.form_data?.subheaderfontsize}rem`,
            color: data?.form_data?.colorpickers.length > 0 ? data?.form_data?.colorpickers[1].colorpicker : '#888888',
          },
          // containLabel: true,
          // labelStyle: {
          //   // fontSize: `${data?.form_data?.subheaderfontsize}rem`,
          //   color: data?.form_data?.colorpickers.length > 0 ? data?.form_data?.colorpickers[1].colorpicker : '#888888',
          // },
          left: 'center',
    }



  const result: any = {
    title : title,
    tooltip: {
      trigger: data.form_data.style_tooltips,
      textStyle: { fontSize: 10 },
      formatter: (params: any) => formatTooltip(params, data, explore, data.form_data.style_tooltips),
    },
    xAxis :
     data.form_data.sparkline_type === 'bar' ? xAxis : {
      ...xAxisObj,
      boundaryGap: false,
      show : false,
      data: chartData?.key.slice(0, data.form_data.limit),
      name: form_data?.x_axis_label || '',
      axisLabel: { margin: 16, fontSize: 10 },
    },
    yAxis : data.form_data.sparkline_type === 'bar' ?  [yAxis, yAxisLine] : yAxis,
    // yAxis: data.form_data.viz_type === 'horizontal_bar' ? xAxis : [yAxis, yAxisLine],
    grid: { left: '10%', top: '30%', bottom: '16%', right: '10%' },
    legend: data.form_data.sparkline_type === 'bar'? {
      ...generateLegend(data, 'bar', chartOption.legend),
      formatter: (param: string) => {
        return param.split('::')[0];
      },
    } : generateLegend(data, 'line', chartData.legends),
    series:data.form_data.sparkline_type === 'line' ? chartData.series : chartOption.series,
  };

  return result;
}


export const findMetricValue = (raw, explore, metricList, metric, index) => {
  const metric_values = raw.data.value[metric];
  const metricName = convert_metric_to_verbose(metric, explore);
  const serieType = index === metricList.length - 1 ? (raw.form_data.line_metric ? 'line' : 'bar') : 'bar';
  return { metric_values, metricName, serieType };
};

