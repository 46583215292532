<!-- OLD STYLE <div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom" style="border-bottom: 0px solid #ebedf2!important;">
		<div class="kt-portlet__head-progress">
			<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
		</div>
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group row">
				<div class="col-lg-12">
					<form [formGroup]="changePasswordForm" autocomplete="off" class="card auth_form signin-form" #f="ngForm" (ngSubmit)="onYesClick()">
						<div class="body pb-5 mx-3">
								<div class="input-group">
									<div class="form-group w-100">
										<input type="password" class="form-control form-bottomline" formControlName="oldPassword" placeholder="Old Password" autocomplete="off">
									</div>
								</div>
								<p class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('oldPassword').errors)}}</strong></p>
			
								<div class="input-group">
									<div class="form-group w-100">
										<input maxlength="12" minlength="3" type="password" class="form-control form-bottomline" formControlName="newPassword" placeholder="New Password" autocomplete="off">
									</div>
								</div>
								
								<p class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('newPassword').errors)}}</strong></p>
								<p *ngIf="isPasswordMatchWithOld" class="mat-error" style="font-size: 75%;" role="alert">Passwords and username must be <strong>different</strong></p>
			
								<div class="input-group">
									<div class="form-group w-100">
										<input type="password" class="form-control form-bottomline" formControlName="comfirmPassword" placeholder="Confirm Password" autocomplete="off">
									</div>
								</div>
								<p  class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('comfirmPassword').errors)}}</strong></p>
								<p *ngIf="isPasswordComfirmPasswordNotMatch" class="mat-error" style="font-size: 75%;" role="alert">Passwords do not<strong>Match</strong></p>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right" style="border-top: 0px solid #ebedf2">
			<div class="kt-form__actions kt-form__actions--sm">
				<button mat-button (click)="onNoClick()">Cancel</button>&nbsp;
				<button mat-button type="submit" (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="viewLoading">Save Changes</button>
			</div>
		</div>
	</div>
</div> -->

<div class="modal-open">
	<div class="modal d-block" role="dialog">
	  <div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
				<h5 class="title f-16">{{data.title}}</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onNoClick()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="col-lg-12">
					<form [formGroup]="changePasswordForm" autocomplete="off" class="auth_form signin-form" #f="ngForm" (ngSubmit)="onYesClick()">
								<div class="input-group">
									<div class="form-group w-100">
										<label class="text-dark"> Old Password </label>
										<input 
											[type]="fieldPassword ? 'text' : 'password'"
											type="password" 
											class="form-control" 
											formControlName="oldPassword" 
											placeholder="" 
											autocomplete="off"
										>
										<a href="javascript:void(0)">
											<span class="showPassword2">
												<i
												class="zmdi"
												[ngClass]="{
												'zmdi-eye-off': !fieldPassword,
												'zmdi-eye': fieldPassword
												}"
												(click)="togglePassword()"
												></i>
											</span>
										</a>
									</div>
								</div>
								<p class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('oldPassword').errors)}}</strong></p>
			
								<div class="input-group">
									<div class="form-group w-100">
										<label class="text-dark">New Password</label>
										<input 
											[type]="fieldPasswordNew ? 'text' : 'password'"
											maxlength="12" 
											minlength="3" 
											type="password" 
											class="form-control" 
											formControlName="newPassword" 
											placeholder="" 
											autocomplete="off"
										>
										<a href="javascript:void(0)">
											<span class="showPassword2">
												<i
												class="zmdi"
												[ngClass]="{
												'zmdi-eye-off': !fieldPasswordNew,
												'zmdi-eye': fieldPasswordNew
												}"
												(click)="togglePasswordNew()"
												></i>
											</span>
										</a>
									</div>
								</div>
								
								<p class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('newPassword').errors)}}</strong></p>
								<p *ngIf="isPasswordMatchWithOld" class="mat-error" style="font-size: 75%;" role="alert">Passwords and username must be <strong>different</strong></p>
			
								<div class="input-group">
									<div class="form-group w-100">
										<label class="text-dark">Confirm Password</label>
										<input 
											[type]="fieldPasswordConfirm ? 'text' : 'password'"
											type="password" 
											class="form-control" 
											formControlName="comfirmPassword" 
											placeholder="" 
											autocomplete="off"
										>
										<a href="javascript:void(0)">
											<span class="showPassword2">
												<i
												class="zmdi"
												[ngClass]="{
												'zmdi-eye-off': !fieldPasswordConfirm,
												'zmdi-eye': fieldPasswordConfirm
												}"
												(click)="togglePasswordConfirm()"
												></i>
											</span>
										</a>
									</div>
								</div>
								<p  class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('comfirmPassword').errors)}}</strong></p>
								<p *ngIf="isPasswordComfirmPasswordNotMatch" class="mat-error" style="font-size: 75%;" role="alert">Passwords do not <strong>Match</strong></p>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-neutral btn-simple btn-sm close px-0" (click)="onNoClick()"> Cancel </button>
				<button class="btn btn-info btn-outline btn-sm f-12" type="submit" (click)="onYesClick()" cdkFocusInitial [disabled]="viewLoading"> Save Changes </button>
			</div>
		</div>
	  </div>
	</div>
</div>